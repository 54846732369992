import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Select, MenuItem, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import { esES } from '@mui/x-date-pickers/locales';

function Recojo({ onBackClick, onEntregaSuccess }) {
  const [pickupDate, setPickupDate] = useState(null);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [driverId, setDriverId] = useState(null);
  
  const ConfirmModal = ({ message, onConfirm, onCancel }) => (
    <div className="modal-overlay-entrega">
      <div 
        className="modal-content"
        style={{
          backgroundColor: 'white',   // Set modal background to white
          padding: '20px',            // Add padding for a clean look
          borderRadius: '8px',        // Optional: Add border radius for smooth corners
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Optional: Add shadow for depth
        }}
      >
        <div 
          className="modal-message"
          style={{
            color: 'black',          // Make font color black
            fontWeight: 'bold',      // Make text a bit bolder
            fontSize: '16px',        // Optional: Adjust font size if needed
            marginBottom: '20px'     // Optional: Add space below the message
          }}
        >
          {message}
        </div>
        <div className="modal-actions" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            className="modal-ok-button"
            onClick={onConfirm}
            style={{
              backgroundColor: 'rgb(255, 43, 133)',  // Optional: Set a color for the button
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            OK
          </button>
          <button
            className="modal-cancel-button"
            onClick={onCancel}
            style={{
              backgroundColor: 'grey', // Optional: Color for the cancel button
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
  

useEffect(() => {
  // Fetch the user's saved addresses
  const fetchAddresses = async () => {
    const userId = localStorage.getItem('user_id');
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user_addresses/${userId}`);
      const nonArchivedAddresses = response.data.addresses.filter(address => !address.archived);
      setAddresses(nonArchivedAddresses);
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  fetchAddresses();
}, []);

  const handleConfirmClick = () => {
    if (!pickupDate) {
        setErrorMessage('Todos los campos obligatorios deben completarse.');
        return;
    }

    setErrorMessage('');
    setIsModalOpen(true);
};

  let driver_1 = ["Barranco", "Chorrillos", "Miraflores", "San Borja", "San Luis", "Santiago de Surco", "Surquillo", "San Juan de Miraflores", "Villa El Salvador", "Villa Maria del Triunfo", "Villa el Salvador", "La Molina"];   
  let driver_3 = ["Ate", "Rímac", "Santa Anita", "El Agustino", "San Juan de Lurigancho"];
  let driver_2 = ["Comas", "Independencia", "Los Olivos", "San Martín de Porres", "Carabayllo", "Puente Piedra", "Ventanilla"];
  let driver_4 = ["Callao", "Jesús María", "Bellavista", "Lince", "Magdalena del Mar", "Carmen de la Legua", "La Perla", "La Punta", "Cercado de Lima", "San Miguel", "San Isidro", "Pueblo Libre", "La Victoria", "Breña"];

  const handleSubmit = async () => {
    setIsModalOpen(false);
    const token = localStorage.getItem('token'); 

    if (!token) {
        console.error("No token available.");
        return;
    }

    try {
        // Format pickupDate to 'YYYY-MM-DD' string
        const formattedPickupDate = pickupDate ? pickupDate.format('YYYY-MM-DD') : null;

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/recojo`, {
            user_id: localStorage.getItem('user_id'),
            pickup_date: formattedPickupDate,  // Send only the date part
            notes: additionalNotes,
            address_id: selectedAddress,
            driver_id: driverId
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log("Response from server:", response.data);
        onEntregaSuccess();
    } catch (error) {
        console.error('Error creating recojo:', error.response?.data || error.message);
    }
  };
    const handleCancel = () => {
      setIsModalOpen(false);
  };

  const handleAddressSelect = (addressId) => {
    const selectedAddressObj = addresses.find(address => address.id === addressId);
  
    // Determine the district of the selected address
    const selectedDistrict = selectedAddressObj.district;  // Assuming there's a 'district' field in the address
  
    // Assign the correct driver_id based on district
    if (driver_1.includes(selectedDistrict)) {
      setDriverId(7);
    } else if (driver_2.includes(selectedDistrict)) {
      setDriverId(54);
    } else if (driver_3.includes(selectedDistrict)) {
      setDriverId(4);
    } else if (driver_4.includes(selectedDistrict)) {
      setDriverId(3);
    } else {
      setDriverId(null);  // If no driver is assigned to the district
    }
  
    setSelectedAddress(addressId);
  };

  const handleDeleteClick = async (addressId) => {
    const token = localStorage.getItem('token');
    
    // Show a confirmation dialog in Spanish
    const confirmation = window.confirm("¿Estás seguro de que deseas eliminar esta dirección?");
    
    if (!confirmation) {
        // If the user clicks "Cancel", do nothing
        return;
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/archive_address/${addressId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Refresh the list of addresses
      const updatedAddresses = addresses.filter(address => address.id !== addressId);
      setAddresses(updatedAddresses);
      alert('La dirección ha sido eliminada correctamente.');
      
    } catch (error) {
      console.error('Error al archivar la dirección:', error.response?.data || error.message);
      alert('Hubo un error al intentar eliminar la dirección.');
    }
  };

  
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="es"
      localeText={
        esES.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <div className="recojo-container">
        <div className="back-arrow">
          <ArrowBackIcon onClick={onBackClick} style={{ color: "black" }} />
        </div>
        
        <h1 style={{ color: "black" }}>Selecciona fecha de recojo</h1>
        <p style={{ color: "black" }}>
          Reserva hasta las 08:00 am del día de entrega
        </p>
        {/* Dropdown to select address */}
        <Select
          value={selectedAddress}
          onChange={(e) => handleAddressSelect(e.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled>Selecciona una dirección</MenuItem>
          {addresses.map((address) => (
            <MenuItem key={address.id} value={address.id}>
              {address.address}
              {/* Add delete icon */}
              <IconButton edge="end" onClick={() => handleDeleteClick(address.id)}>
                <DeleteIcon />
              </IconButton>
            </MenuItem>
          ))}
        </Select>
        <DatePicker
          label="Fecha de Recojo"
          inputFormat="DD/MM/YYYY"
          value={pickupDate}
          onChange={(newValue) => setPickupDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
        <TextField
          label="Notas Adicionales"
          multiline
          rows={4}
          value={additionalNotes}
          onChange={(e) => setAdditionalNotes(e.target.value)}
          margin="normal"
        />
        <p style={{ color: "black" }}>
          El currier te visitara entre las 09:00-12:00 (hora local en
          Lima)
        </p>

        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <Button
          variant="contained"
          onClick={handleConfirmClick}
          style={{ backgroundColor: "#000", color: "#fff", width: "100%" }}
        >
          Confirmar
        </Button>
        {isModalOpen && (
          <ConfirmModal
            message="¿Está seguro de que la información ingresada para el recojo es correcta?"
            onConfirm={handleSubmit}
            onCancel={handleCancel}
          />
        )}
      </div>
    </LocalizationProvider>
  );
}

export default Recojo;
