import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import ReactDOMServer from 'react-dom/server';
import { Fab, Box } from "@mui/material";
import AltRouteIcon from '@mui/icons-material/AltRoute';
import SendIcon from '@mui/icons-material/Send';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IconButton } from '@mui/material';
import StadiumIcon from '@mui/icons-material/Stadium';
import ChecklistIcon from '@mui/icons-material/Checklist';
import axios from 'axios';
import Search from './Search';
import SearchResultCard from './SearchResultCard';
import SearchOrderResultCard from './SearchOrderResultCard';
import SearchUserResultCard from './SearchUserResultCard';
import SearchDriverResultCard from './SearchDriverResultCard';
import MapIcon from '@mui/icons-material/Map';
import { districts } from '../user/Districs';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;


function MapAdmin({ setActiveComponent, onOptionClick, userId }) {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const driverMarkersRef = useRef({});
    const [userMarker, setUserMarker] = useState(null);
    const [user, setUser] = useState(null)
    const [deliveryPoints, setDeliveryPoints] = useState([]);
    const [orderPoints, setOrderPoints] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    const [currentMarkers, setCurrentMarkers] = useState([]);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/user/${userId}`)
          .then(response => {
            setUser(response.data);
            console.log('user ', response.data);
          })
          .catch(error => console.error('Error fetching data:', error));
      }, [userId]);

      useEffect(() => {
        const fetchDrivers = async () => {
          try {
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/driver/location`);
            setDrivers(response.data);
    
            // Iterate over the fetched drivers and add markers for each
            response.data.forEach(driver => addDriverMarker(driver));
          } catch (error) {
            console.error('Error fetching drivers:', error);
          }
        };
    
        // Initial fetch
        fetchDrivers();
    
        // Fetch every 5 seconds
        const intervalId = setInterval(fetchDrivers, 5000);
    
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
      }, []);


    useEffect(() => {
        const fetchDeliveryPoints = async () => {
            try {
                
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_points/admin`);
                console.log("Fetched delivery points:", response.data);
                setDeliveryPoints(response.data);
            } catch (error) {
                console.error("Error fetching delivery points:", error);
            }
        };

        fetchDeliveryPoints();

        const intervalId = setInterval(fetchDeliveryPoints, 5000);
    
        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures this runs once on mount




    useEffect(() => {
        const fetchOrders = async () => {
            try {
                
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/delivery_orders/admin`);
                console.log("Fetched orders:", response.data);
                setOrderPoints(response.data);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        fetchOrders();
    }, [deliveryPoints]);


    
    
    const addDriverMarker = (driver) => {
        if (driverMarkersRef.current[driver.id]) {
            // Remove the existing marker if it exists
            driverMarkersRef.current[driver.id].remove();
        }
    
        // Create a new marker element for the driver
        const markerElement = document.createElement('div');
        markerElement.innerHTML = `<img src="${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png" alt="Logo" style="height: 30px; width: 30px;" />`;
    
        const newMarker = new mapboxgl.Marker({
            element: markerElement,
            anchor: 'center'
        })
        .setLngLat([driver.longitude, driver.latitude])
        .addTo(mapRef.current);
    
        newMarker.getElement().addEventListener('click', () => {
            handleDriverMarkerClick(driver.id);
        });
    
        // Store the new marker in the ref
        driverMarkersRef.current[driver.id] = newMarker;
        console.log("Adding marker for driver ID:", driver.id);
      };



      const initializeUserMarker = () => {
        if (userMarker) {
          userMarker.remove();
        }
        
        console.log("initializing usermarker");
      
        if (user && user.addresses && user.addresses.length > 0) {
          // Iterate over the user's addresses and create a marker for each one
          user.addresses.forEach((address) => {
            if (address.latitude && address.longitude) {
              // Create a DOM element containing the StoreIcon
              const storeIconElement = document.createElement('div');
              storeIconElement.innerHTML = ReactDOMServer.renderToString(
                <StadiumIcon style={{ fontSize: '40px', color: 'black' }} />
              );
              
              // Create the marker using the store icon for each address
              const marker = new mapboxgl.Marker({
                element: storeIconElement
              })
                .setLngLat([address.longitude, address.latitude])  // Set marker position to the address's latitude and longitude
                .setPopup(new mapboxgl.Popup().setHTML(`<h2 style="color: black;">${user.username}</h2><p>${address.address}</p>`))
                .addTo(mapRef.current);
      
              setUserMarker(marker);
            }
          });
        }
      };

      useEffect(() => {
        if (mapContainerRef.current && !mapRef.current) {
          mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [-77.03711992646711, -12.060235303763926], // Default coordinates (Lima)
            zoom: 16
          });
      
          mapRef.current.on('load', () => {
            initializeUserMarker();
            // initializeDeliveryPointMarkers(); if necessary but doble markers are showing
            fitMapToMarkers();
          });
        } else if (mapRef.current) {
          initializeUserMarker();
          
          fitMapToMarkers();
        }
      }, [user]);

    const clearMarkers = () => {
        currentMarkers.forEach(marker => marker.remove());
        setCurrentMarkers([]);
    };


    const fitMapToMarkers = () => {
        if (mapRef.current) {
          const bounds = new mapboxgl.LngLatBounds();
      
          // Add user location to bounds
          if (user && user.default_latitude && user.default_longitude) {
            bounds.extend([user.default_longitude, user.default_latitude]);
          }
      
          // Add delivery points to bounds
          deliveryPoints.forEach(point => {
            if (point.longitude && point.latitude) {
              bounds.extend([point.longitude, point.latitude]);
            }
          });
          drivers.forEach(driver => {
            if (driver.longitude && driver.latitude) {
              bounds.extend([driver.longitude, driver.latitude]);
            }
          })
      
          // Fit the map to the bounds, with some padding
          if (!bounds.isEmpty()) {
            mapRef.current.fitBounds(bounds, { padding: 50 });
          }
        }
      };
   

    useEffect(() => {
        console.log('Delivery a points:', deliveryPoints);
    }, [deliveryPoints]);


    const handleEntregasClick = async () => {
        console.log("Fetching delivery points...");
    
        clearMarkers();
    
        deliveryPoints.forEach(point => {
            let { latitude, longitude } = point;
    
            // Check if latitude and longitude are missing
            if (!latitude || !longitude) {
                // Look for the district data
                const districtData = districts[point.district];
                if (districtData) {
                    // Set latitude and longitude based on the district data with random offset
                    latitude = districtData.latitude + (Math.random() - 0.5) * 0.01;
                    longitude = districtData.longitude + (Math.random() - 0.5) * 0.01;
                }
            }
    
            // Only create a marker if we have valid coordinates
            if (latitude && longitude) {
                // Determine the marker color based on the status
                let color;
                switch (point.status) {
                    case 'pending':
                        color = 'gray';
                        break;
                    case 'entregado':
                        color = 'green';
                        break;
                    case 'cancel':
                        color = 'red';
                        break;
                    case 'encamino':
                        color = 'orange';
                        break;
                    default:
                        color = 'blue'; // default color if status doesn't match any case
                }
    
                // Create a custom HTML element for the marker
                const markerElement = document.createElement('div');
                markerElement.innerHTML = `
                    <div style="position: relative; width: 40px; height: 50px;">
                        <div style="width: 25px; height: 25px; border-radius: 50%; background-color: ${color}; display: flex; align-items: center; justify-content: center; font-weight: bold; color: white;">
                            ${point.sequence && point.driver_id ? `${point.sequence}-${point.driver_id}` : point.id}
                        </div>
                    </div>
                `;
                markerElement.style.cursor = 'pointer';
    
                // Create a Mapbox marker with the custom element
                const marker = new mapboxgl.Marker({
                    element: markerElement
                })
                .setLngLat([longitude, latitude])
                .addTo(mapRef.current);
    
                // Make the marker clickable
                marker.getElement().addEventListener('click', () => {
                    handleDeliveryMarkerClick(point.id);
                });
    
                setCurrentMarkers(prevMarkers => [...prevMarkers, marker]);
            }
        });
    };

    const handleRecojosClick = async () => {
        console.log("Fetching orders...");
    
        clearMarkers();
    
        orderPoints.forEach(point => {
            let { latitude, longitude } = point;
    
            // Check if latitude and longitude are missing
            if (!latitude || !longitude) {
                // Look for the district data
                const districtData = districts[point.distric];
                if (districtData) {
                    // Set latitude and longitude based on the district data with random offset
                    latitude = districtData.latitude + (Math.random() - 0.5) * 0.01;
                    longitude = districtData.longitude + (Math.random() - 0.5) * 0.01;
                }
            }
    
            // Only create a marker if we have valid coordinates
            if (latitude && longitude) {
                // Determine the marker color based on the status
                let color;
                switch (point.status) {
                    case 'pending':
                        color = 'gray';
                        break;
                    case 'recogido':
                        color = 'green';
                        break;
                    case 'cancelado':
                        color = 'red';
                        break;
                    case 'encamino':
                        color = 'orange';
                        break;
                    default:
                        color = 'blue'; // default color if status doesn't match any case
                }
    
                // Create a custom HTML element for the marker
                const markerElement = document.createElement('div');
                markerElement.innerHTML = `
                   <div style="position: relative; width: 40px; height: 50px;">
                        <div style="width: 25px; height: 25px; border-radius: 50%; background-color: ${color}; display: flex; align-items: center; justify-content: center; font-weight: bold; color: white;">
                            ${point.sequence && point.driver_id ? `${point.sequence}-${point.driver_id}` : point.id}
                        </div>
                    </div>
                `;
                markerElement.style.cursor = 'pointer';
    
                // Create a Mapbox marker with the custom element
                const marker = new mapboxgl.Marker({
                    element: markerElement
                })
                .setLngLat([longitude, latitude])
                .addTo(mapRef.current);
    
                marker.getElement().addEventListener('click', () => {
                    handleOrderMarkerClick(point.id);
                });
    
                setCurrentMarkers(prevMarkers => [...prevMarkers, marker]);
            }
        });
    };

    const handleLogoClick = () => {
        setActiveComponent('OptionsAdmin')
      }

      const handleSearchClick = () => {
        setIsSearchModalOpen(true);
    };

    const handleResumenClick = () => {
        setActiveComponent('ResumenAdmin');
    };

    const handleSearch = (result, searchType) => {
      console.log('Search result:', result);
      console.log('Search type:', searchType);
      setSearchResult({ result, searchType });
      setIsSearchModalOpen(false);
  };

  const handleDeliveryMarkerClick = (id) => {
    const result = deliveryPoints.find(point => point.id === id);
    const searchType = 'entrega_id';
    console.log('Search result:', result);
    console.log('Search type:', searchType);
    setSearchResult({ result, searchType });
    setIsSearchModalOpen(false);
  };

  const handleOrderMarkerClick = (id) => {
    const result = orderPoints.find(point => point.id === id);
    const searchType = 'recojo_id';
    console.log('Search result:', result);
    console.log('Search type:', searchType);
    setSearchResult({ result, searchType });
    setIsSearchModalOpen(false);
    };

    const handleDriverMarkerClick = async (id) => {
        try {
            const token = localStorage.getItem('token');
            // Make the API call to fetch the driver data
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/user`, {
                params: {
                    id: id  // Assuming the API expects 'user_id' as a query parameter
                },
                headers: { Authorization: `Bearer ${token}` },
            });
    
            // Get the driver data from the response
            const result = response.data; // Assuming the API response is the driver data
            const searchType = 'driver';
    
            // Log the result and search type
            console.log('Search result:', result);
            console.log('Search type:', searchType);
    
            // Set the search result and close the modal
            setSearchResult({ result, searchType });
            setIsSearchModalOpen(false);
        } catch (error) {
            console.error('Error fetching driver data:', error.response ? error.response.data : error.message);
            // You might want to handle errors here, e.g., show a message to the user
        }
    }; 


    const handleCloseSearchResult = () => {
        setSearchResult(null);
    };
    
    const handleRoutesClick = () => {
        onOptionClick('Routes');
    };

    const handleSendClick = () => {
        setActiveComponent('Mensajeria');
    };

    const handleMapClick = () => {
        setActiveComponent('Location');
    };  
    
    return (
        <div>
            <div ref={mapContainerRef} style={{ width: "100%", height: "100vh" }}></div>
            <div style={{ position: "absolute", top: "25px", left: "25px", zIndex: 2000, }} onClick={handleLogoClick} >
                <img src={`${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`} alt="Logo" style={{ height: "78px", width: "78px" }} />
            </div>
            <IconButton
                    style={{
                    position: "absolute",
                    bottom: "75%",
                    right: 100,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "50%",
                    color: "black",
                    marginBottom: "10px",
                    transform: "scale(1.7)",
                    }}
                    onClick={handleMapClick}
                >
                    <MapIcon />
                </IconButton>
            <IconButton
                    style={{
                    position: "absolute",
                    bottom: "65%",
                    right: 100,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "50%",
                    color: "black",
                    marginBottom: "10px",
                    transform: "scale(1.7)",
                    }}
                    onClick={handleResumenClick}
                >
                    <ChecklistIcon />
                </IconButton>
                <IconButton
                    style={{
                    position: "absolute",
                    bottom: "55%",
                    right: 100,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "50%",
                    color: "black",
                    marginBottom: "10px",
                    transform: "scale(1.7)",
                    }}
                    onClick={handleSearchClick}
                >
                    <SearchIcon />
                </IconButton>
                <IconButton
                    style={{
                    position: "absolute",
                    bottom: "45%",
                    right: 100,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "50%",
                    color: "black",
                    marginBottom: "10px",
                    transform: "scale(1.7)",
                    }}
                    onClick={handleSendClick}
                >
                    <SendIcon />
                </IconButton>
                <IconButton
                    style={{
                    position: "absolute",
                    bottom: "35%",
                    right: 100,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "50%",
                    color: "black",
                    marginBottom: "10px",
                    transform: "scale(1.7)",
                    }}
                    onClick={handleRoutesClick}
                >
                    <AltRouteIcon />
                </IconButton>
                <IconButton
                    style={{
                    position: "absolute",
                    bottom: "25%",
                    right: 100,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "50%",
                    color: "black",
                    marginBottom: "10px",
                    transform: "scale(1.7)",
                    }}
                    onClick={handleRecojosClick}
                >
                    <PersonPinIcon />
                </IconButton>
                <IconButton
                    style={{
                    position: "absolute",
                    bottom: "10%",
                    right: 100,
                    zIndex: 1000,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderRadius: "80%",
                    color: "black",
                    marginBottom: "10px",
                    transform: "scale(2.7)",
                    }}
                    onClick={handleEntregasClick}
                >
                    <LocationOnIcon />
                </IconButton>
            
            {isSearchModalOpen && <Search onSearch={handleSearch} onClose={() => setIsSearchModalOpen(false)} />}
            
            {
              searchResult && searchResult.searchType === 'entrega_id' && (
                  <Box sx={{ position: "absolute", top: "80px", width: "70%", left: "10px", zIndex: 2000}}>
                      <SearchResultCard
                          onSearch={handleSearch}
                          deliveryPoint={searchResult.result}
                          onClose={handleCloseSearchResult}
                      />
                  </Box>
              )
          }

          {
              searchResult && searchResult.searchType === 'recojo_id' && (
                  <Box sx={{ position: "absolute", top: "80px", width: "70%", left: "10px", zIndex: 2000 }}>
                      <SearchOrderResultCard
                          onSearch={handleSearch}
                          order={searchResult.result}
                          onClose={handleCloseSearchResult}
                      />
                  </Box>
              )
          }
        {
            searchResult && searchResult.searchType === 'user' && (
                <Box sx={{ position: "absolute", top: "80px", width: "55%", left: "10px", zIndex: 2000 }}>
                    <SearchUserResultCard
                        user={searchResult.result}
                        onClose={handleCloseSearchResult}
                    />
                </Box>
            )  
        }
        {
            searchResult && searchResult.searchType === 'driver' && (
                <Box sx={{ position: "absolute", top: "60px", width: "70%", left: "10px", zIndex: 2000 }}>
                    <SearchDriverResultCard
                        user={searchResult.result}
                        onClose={handleCloseSearchResult}
                    />
                </Box>
            )   
        }

        </div>
    );
}

export default MapAdmin;
