import React, { useState } from 'react';
import { Button, TextField, Container, Avatar, ListItem, ListItemAvatar, InputAdornment } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DistrictSelect from './DistrictSelect';
import 'dayjs/locale/es';
import { esES } from '@mui/x-date-pickers/locales';

function EditDeliveryPoint({ deliveryPoint, onBackClick, onSubmit }) {
    const [recipientName, setRecipientName] = useState(deliveryPoint.recipient_name);
    const [phone, setPhone] = useState(deliveryPoint.recipient_phone);
    const [district, setDistrict] = useState(deliveryPoint.district);
    const [amountToCharge, setAmountToCharge] = useState(deliveryPoint.charge);
    const [notes, setNotes] = useState(deliveryPoint.notes);
    const [address, setAddress] = useState(deliveryPoint.address);

    const handleSubmit = (event) => {
        event.preventDefault();
        const updatedDeliveryPoint = {
            user_id: deliveryPoint.user_id,
            id: deliveryPoint.id,
            recipient_name: recipientName,
            recipient_phone: phone,
            district,
            charge: amountToCharge,
            notes,
            address
        };

        onSubmit(updatedDeliveryPoint);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
            <div className="entrega-container">
                <ArrowBackIcon onClick={onBackClick} style={{ color: 'black' }}/>
                <div className="calendar-image" >
                    <CalendarTodayIcon style={{ color: 'black' }} />
                </div>    
                <h1 style={{ color: 'black' }}>Edita tu Entrega</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                    <TextField
                        required
                        label="Nombre y Apellido"
                        fullWidth
                        value={recipientName}
                        onChange={(event) => setRecipientName(event.target.value)}
                        margin="normal"
                    />
                    <TextField
                        required
                        label="WhatsApp"
                        fullWidth
                        value={phone}
                        onChange={(event) => {
                            const input = event.target.value;
                            const phoneNumber = input.slice(-9);
                            setPhone(phoneNumber);
                        }}
                        margin="normal"
                    />
                    </div>
                    <div className="form-row">
                    <DistrictSelect district={district} setDistrict={setDistrict} />
                    <TextField
                        required
                        label="Monto a Cobrar"
                        
                        value={amountToCharge}
                        onChange={(event) => setAmountToCharge(event.target.value)}
                        margin="normal"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">S/.</InputAdornment>,
                        }}
                        type="number"
                    />
                    </div>
                    <TextField
                        label="Notas adicionales"
                        fullWidth
                        multiline
                        rows={1}
                        value={notes}
                        onChange={(event) => setNotes(event.target.value)}
                        margin="normal"
                    />
                    <TextField
                        label="Dirección"
                        fullWidth
                        multiline
                        rows={1}
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                        margin="normal"
                    />
                    <Button type="submit" fullWidth variant="contained" style={{ backgroundColor: '#000', color: '#fff', marginTop: '20px' }}>
                        Guardar
                    </Button>
                </form>
            </div>
        </LocalizationProvider>
    );
}

export default EditDeliveryPoint;